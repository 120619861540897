<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <router-link :to="'/analyses/addBplan'">
          Business Plan >
        </router-link>
        <router-link :to="'/analyses/business-plan/'+$route.params.projet+'/produits'">
          Produits >
        </router-link>
        <router-link :to="'/analyses/business-plan/'+$route.params.projet+'/produits/'+$route.params.produit+'/charges_ressources'">
          Charges & Ressources >
        </router-link>
        <!-- <router-link :to="'/analyses/bplan'">
          
        </router-link> -->
        <span>Détail Charge</span>
      </div>
    </div>
    <div>
      <div class="row justify-content-center my-4">
        <div class="col-8">
          <div class="card title-card">
            <div class="card-body text-center title">
              Business plan
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <form class="accordion col-md-4 mx-auto" 
            id="accordionExample">
        <table  class="table ">
          <thead class="card-header" 
                 id="headingOne">
            <tr class="tht-blue"
                data-toggle="collapse" 
                data-target="#collapseOne" 
                aria-expanded="true" 
                aria-controls="collapseOne">
              <th class="pl-3"> <strong>Informations de la charge</strong></th>
            </tr>
          </thead>
          <div id="collapseOne" 
               class="collapse show" 
               aria-labelledby="headingOne" 
               data-parent="#accordionExample">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-md-5">
                  <label class="req">Type de la charge</label>
                </div>  
                <div class="form-group col-md-6">
                  {{ charge.groupe }}
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-5"
                     id="base"
                     ref="base">
                  <label>Libelle</label>
                </div>
                <div class="form-group col-md-6"
                     id="base"
                     ref="base"
                     v-if="charge">
                  {{ charge.libelle }}
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-5">
                  <label class="req">Période</label>
                </div>  
                <div class="form-group col-md-6">
                  {{ charge.periodes=="mois"?"Mensuelle":"Annuelle" }}
                </div>
              </div>
            </div>
          </div> 
        </table>
      </form>
    </div>
    <div class="row">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="th-blue text-right"> </th>
            <th v-for="(cle,clekey) in listKey"
                :key="clekey"
                class="text-right tht-blue text-capitalize">
              {{ cle }}
            </th>
          </tr>
        </thead>
        <tbody v-if="!loaded">
          <tr scope="row">
            <td colspan="7"
                class="text-center">Chargement en cours... </td>
          </tr>
        </tbody>
        <tbody v-else-if="loaded && long ==0">
          <tr scope="row">
            <td colspan="7"
                class="text-center">Aucune donnée enregistrée...</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr scope="row">
            <td >Coût</td>
            <td v-for="(echeance, echeankey) in listEcheance"
                :key="echeankey"
                class="text-right">
              {{ echeance.toLocaleString() }}
            </td>
          </tr>
          <tr scope="row">
            <td >Quantité</td>
            <td v-for="(quantite, quantkey) in charge.echeances"
                :key="quantkey"
                class="text-right th-blue">
              {{ quantite.quantite.toLocaleString() }}
            </td>
          </tr>
          <tr scope="row">
            <td class="tht-blue">Total</td>
            <td v-for="(echeance, echkey) in charge.echeances"
                :key="echkey"
                class="text-right tht-blue">
              {{ Number(echeance.quantite*echeance.montant).toLocaleString() }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    

  </div>
</template>
<style>
@import "./businessPlan.css";
.table {
  font-size: 0.54em;
}
form.accordion {
  font-size: 1.3em;
}
li.nav-item, 
div.col-md-3.mx-auto h4,
div.ent-title,
div.ent-vt-title,
div.ent-value,
div.ent-vt-value,
.result-title,
.bg-secondary{
  font-size: 0.7em;
}

</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

// import form from "vuejs-form"
// import Notif from "@/components/shared/Toast"
export default {
  name: "DetailCharges",
  components: {
    
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    loaded:false,
    long:0,
    listKey:[],
    listEcheance:[],
    listMois:[
      "janv.",
      "fevr.",
      "mars",
      "avr.",
      "mai",
      "juin",
      "juil.",
      "aout",
      "sept.",
      "oct.",
      "nov.",
      "dec.",
    ]
  }),
  created() {
    this.getCharge({projet:this.$route.params.projet, produit:this.$route.params.produit, charge:this.$route.params.charge})
  },
  watch: {
    charge(){
      if (this.charge) {
        this.charge.echeances.forEach(echeance => {
          if (echeance.date!= null && echeance.date!= "" && echeance.date!= "0000-00-00") {
            var title=""
            if (this.charge.periodes=="mois") {
              title = echeance.date.split("-")[1]
              if (title <10) {
                title = title.split("")[1]                
              }
              this.listKey.push(this.listMois[title-1])

              console.log(title)
            }else{
              this.listKey.push(echeance.date)
            }
          }
          this.listEcheance.push(echeance.montant)
        })
        console.log(this.listKey)
        this.long= this.listEcheance.length
        this.loaded= true
      }
    }
  },
  computed: {
    ...mapGetters(["charge","failCharge"])
  },
  methods: {
    ...mapActions(["getCharge"]),
    ...mapMutations(["setCharge","setFailCharge"]),
    
  }
}
</script>
